
import { Component, Vue, Watch } from 'vue-property-decorator'
import { SplitFeatureFlag, SplitKeyType } from '@/utils/enum'
import { RedirectLoginOptions } from '@auth0/auth0-spa-js'
import { RawLocation } from 'vue-router'

@Component({
  metaInfo: {
    title: 'Login',
  },
})
export default class LoginComponent extends Vue {
  email: string = ''
  password: string = ''
  authError: string | null = null
  submitting: boolean = false
  isAuth0Enabled: boolean = false

  @Watch('isAuth0Enabled')
  onIsAuth0EnabledChanged() {
    this.goToAuth0Login()
  }

  created() {
    this.waitForSplit()
  }

  get isReady() {
    return this.$store.state.split.isReady
  }

  async waitForSplit() {
    const delay = (ms: number) =>
      new Promise((resolve) => setTimeout(resolve, ms))

    try {
      await this.$store.dispatch('split/updateKey', {
        key: SplitKeyType.AnonymousUser,
        forceRefresh: true,
      })

      // Polling loop to wait until the split is ready
      while (!this.isReady) {
        await delay(100)
      }

      // Fetch feature flag to determine if Auth0 is enabled
      const auth0QueryParam = this.$route.query.auth0
      // only use the queryparam if it is exactly 'true' or 'false'
      // ignore if it is something like ?auth0=t or ?auth0=f
      if (auth0QueryParam === 'true') {
        this.isAuth0Enabled = true
      } else if (auth0QueryParam === 'false') {
        this.isAuth0Enabled = false
      } else {
        this.isAuth0Enabled = await this.$store.dispatch('split/isFeatureEnabled', SplitFeatureFlag.Auth0SignIn)
      }
    } catch (error) {
    }
  }

  tryToLogIn() {
    this.submitting = true
    this.authError = null

    this.$store
      .dispatch('auth/logIn', {
        email: this.email,
        password: this.password,
      })
      .then((token) => {
        this.submitting = false
        this.$store.dispatch('callCenter/initializeCallCenter')
        this.$router.push(
          (this.$route.query.redirectFrom as RawLocation) || { name: 'home' }
        )
      })
      .catch((error) => {
        this.submitting = false
        this.authError = error
      })
  }

  goToAuth0Login() {
    if (!this.isAuth0Enabled) {
      return
    }
    const options: RedirectLoginOptions = {
      appState: {
        routeName: 'home',
        redirectFrom: this.$route.query.redirectFrom,
      },
      authorizationParams: {
        redirect_uri: window.location.origin + '/ci/auth-callback',
      },
    }

    this.$auth0.loginWithRedirect(options)
  }
}
